import { useEffect, useRef } from 'react';

/**
 * useUpdateEffect runs only when the dependencies change, not on mount.
 */
export default function useUpdateEffect(callback, dependencies) {
    const firstRenderRef = useRef(true);

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;

            return;
        }

        return callback();
    }, dependencies);
}
