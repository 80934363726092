import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';

import useUpdateEffect from 'hooks/update-effect/useUpdateEffect';
import { generateImageUrl } from 'services/url';
import { getAllShopItems } from 'store/shop/shop.action';

let cache = null;

export default function usePostShop(search, tags /* , { shouldFetch } = { shouldFetch: true } */) {
    const dispatch = useDispatch();
    const { items: data, itemsError: error, itemsIsLoading: isLoading } = useSelector((state) => state.shopReducer);

    // update cached data on store update
    // useUpdateEffect(() => {
    //     if (data || !shouldFetch) return;

    //     dispatch(getAllShopItems(search, tags, page));
    // }, [data]);

    const { ref, inView } = useInView({
        threshold:     0,
        initialInView: false,
    });

    const [shopItems, setShopItems] = useState([]);
    const [page, setPage] = useState(0);
    const [refetch, setRefetch] = useState(false);

    useEffect(() => {
        if (inView) {
            setPage((current) => current + 1);
            setRefetch((prev) => !prev);
        }
    }, [inView]);

    useUpdateEffect(() => {
        setShopItems([]);
        setPage(0);
        setRefetch((prev) => !prev);
    }, [tags, search]);

    useEffect(() => {
        if ((data && page > data.totalPages) || isLoading) {
            return;
        }

        dispatch(getAllShopItems(search, tags, page));
    }, [refetch]);

    useEffect(() => {
        if (!data) {
            return;
        }

        const modifiedItems = data.list.map((el) => ({
            ...el,
            thumbnail: generateImageUrl(el.thumbnail),
        }));

        setShopItems((current) => {
            if (current && current?.length > 0 && cache && page > cache.page) {
                return [...current, ...modifiedItems];
            }

            cache = { tags, search, page };

            return modifiedItems;
        });
    }, [data]);

    const fetch = () => dispatch(getAllShopItems());

    return {
        ref,
        inView,
        data,
        shopItems,
        error,
        isLoading,
        fetch,
    };
}
