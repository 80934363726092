import { Config } from 'config';
import { OneSignalParams } from 'constants/oneSignal';
import OneSignal from 'react-onesignal';

type InitOneSignalReturnType = Promise<{success: boolean; OneSignal: typeof OneSignal }>;

async function initOneSignal(): InitOneSignalReturnType {
    let initialParams = OneSignalParams.localhost;

    if (Config.isProduction) {
        initialParams = window.location.origin.includes('client.dev.taoo.ai')
            ? OneSignalParams.develop
            : OneSignalParams.production;
    }

    return OneSignal
            .init({
                ...initialParams,

                // serviceWorkerParam: { scope: '/onesignal' },
                // serviceWorkerPath:  '/OneSignalSDKWorker.js',
                // serviceWorkerPath: '/OneSignalSDK.sw.js',
            })
            .then(() => {
                console.log('OneSignal init successful!');
                OneSignal.Slidedown.promptPush();

                return { success: true, OneSignal };
            })
            .catch(() => {
                console.error('OneSignal init Failed!');

                return { success: false, OneSignal };
            });
}

const subscribeWithEmail = (email: string): void => OneSignal.User.addEmail(email);

const loginWithID = async (
    externalId: string,
    jwtToken?: string,
): Promise<void> => await OneSignal.login(externalId, jwtToken);

const logout = async (): Promise<void> => await OneSignal.logout();

export const signalNotification = {
    init:  initOneSignal,
    subscribeWithEmail,
    login: loginWithID,
    logout,
};
