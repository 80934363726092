import { endpoints } from 'constants/api';
import TaooServerClient from 'http/axios';
import { useEffect, useState } from 'react';
import { StoryItem } from 'shared/interfaces/story';

export default function useGetStories() {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [stories, setStories] = useState<StoryItem[]>([]);

    useEffect(() => {
        setIsLoading(true);

        getStories()
                .then((stories) => {
                    setStories(stories.filter(story => story.active));
                })
                .catch(setError)
                .finally(() => setIsLoading(false));
    }, []);

    return {
        stories,
        error,
        isLoading,
        hasActiveStories: stories.length > 0 ?? false,
    };
}

const getStories = async () => {
    try {
        const response = await TaooServerClient.get<StoryItem[]>(endpoints.story.getStories);

        if (!response || !response?.data) {
            throw new Error('API Error!');
        }

        return response.data;
    } catch (e: unknown) {
        console.error(e);
    }
};
