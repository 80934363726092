import { AnimatePresence, PanInfo, motion } from 'framer-motion';
import {
    CSSProperties, ReactNode, useEffect, useState,
} from 'react';

import './draggablePopup.scss';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import DraggablePopupPages from './DraggablePopupPages';

export interface DraggablePopupProps {
    show: boolean;
    shouldShowHandle?: boolean;
    onClose?: () => void;
    children: ReactNode;
    noMinHeight?: boolean;
    isDraggable?: boolean;
    closeOnBackdropClick?: boolean;
    wrapperStyle?: CSSProperties;
    className?: string;
    useSecondPortal?: boolean;
    animate?: boolean;
}

const variants = {
    hidden: {
        y:          1000,
        transition: { duration: 0.3 },
    },
    visible: {
        y:          0,
        transition: {
            type:      'spring',
            damping:   30,
            stiffness: 300,
        },
    },
};

const DraggablePopup = ({
    show,
    shouldShowHandle,
    onClose,
    children,
    noMinHeight,
    isDraggable,
    closeOnBackdropClick,
    wrapperStyle,
    className,
    useSecondPortal,
    animate = true,
}: DraggablePopupProps): JSX.Element => {
    const id = useSecondPortal ? 'draggable-popup-sec-container' : 'draggable-popup-container';
    const [portalContainer, setPortalContainer] = useState(document.getElementById(id));

    useEffect(
        () => setPortalContainer(document.getElementById(id)), [],
    );

    const handleDragEnd = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
        if (info.offset.y > 200) {
            onClose?.();
        }
    };

    return portalContainer ? createPortal(
        <AnimatePresence>
            {show && (
                <>
                    <motion.div
                        initial={animate ? { opacity: 0 } : {}}
                        animate={animate ? { opacity: 0.6 } : {}}
                        exit={animate ? { opacity: 0 } : {}}
                        transition={{ duration: 0.2 }}
                        className="draggable-popup-backdrop"
                        onClick={closeOnBackdropClick && onClose}
                    />
                    <motion.div
                        className={classNames('draggable-popup', { [className]: !!className })}
                        variants={animate ? variants : {}}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        drag={isDraggable ? 'y' : false}
                        dragConstraints={{ top: 0, bottom: 0 }}
                        dragElastic={{ top: 0, bottom: 0.8 }}
                        onDragEnd={handleDragEnd}
                        style={noMinHeight ? { minHeight: 'unset', ...wrapperStyle } : wrapperStyle}
                    >
                        {shouldShowHandle && <div className="draggable-popup-drag-handle" />}

                        {children}
                    </motion.div>
                </>
            )}
        </AnimatePresence>,
        portalContainer,
    ) : null;
};

DraggablePopup.defaultProps = {
    onClose:              undefined,
    shouldShowHandle:     true,
    noMinHeight:          false,
    isDraggable:          true,
    closeOnBackdropClick: true,
    wrapperStyle:         {},
    className:            '',
};

DraggablePopup.PagesContainer = DraggablePopupPages;

export default DraggablePopup;
