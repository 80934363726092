import { endpoints } from 'constants/api';
import TaooServerClient from 'http/axios';

import * as types from './shop.types';

export const getAllShopItems = (search, tags, page) => async function (dispatch) {
    dispatch({ type: types.SHOP_GET_ALL_REQUEST });

    try {
        const response = await TaooServerClient.post(endpoints.shop.postShop, { search, tags, page });

        if (response.status === 200) {
            const data = response?.data ?? null;
            const isFilteredReturnedNoResults = (tags.length || search) && !data;

            dispatch(getAllShopItemsSuccess(isFilteredReturnedNoResults ? [] : data));
        } else {
            dispatch(getAllShopItemsError(response));
        }
    } catch (error) {
        dispatch(getAllShopItemsError(error));
    }
};

function getAllShopItemsSuccess(results) {
    return {
        type:  types.SHOP_GET_ALL_SUCCESS,
        items: results,
        error: null,
    };
}

function getAllShopItemsError(error) {
    return {
        type:  types.SHOP_GET_ALL_ERROR,
        items: null,
        error,
    };
}

export const getShopSingle = (id) => async function (dispatch) {
    dispatch({ type: types.SHOP_GET_SINGLE_REQUEST });

    try {
        const response = await TaooServerClient.get(endpoints.shop.getVoucherSingle(id));

        if (response.status == 200) {
            dispatch(getShopSingleSuccess(response.data));
        } else {
            dispatch(getShopSingleError(response));
        }
    } catch (error) {
        dispatch(getShopSingleError(error));
    }
};

function getShopSingleSuccess(results) {
    return {
        type:       types.SHOP_GET_SINGLE_SUCCESS,
        shopSingle: results,
        error:      null,
    };
}

function getShopSingleError(error) {
    return {
        type:       types.SHOP_GET_SINGLE_ERROR,
        shopSingle: null,
        error,
    };
}
