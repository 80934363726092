import Loader from 'components/loaders/loader';
import useTags from 'hooks/tags/useTags';
import CheckBox from 'pages/home/checkbox';
import { triggerFocus } from 'services/events';
import debounce from 'lodash/debounce';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

// eslint-disable-next-line
// @ts-ignore
import classNames from 'classnames';
import styles from './homeFilter.module.scss';

interface HomeFilterProps {
    className?: string;
    handleChange: (value: string) => void;
    handleTagClick: (value: boolean) => void;
}

const HomeFilter = ({ handleTagClick, handleChange, className }: HomeFilterProps) => {
    const { data: tagsData, isLoading: tagsDataIsLoading } = useTags();
    const intl = useIntl();
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const searchRef = useRef();

    const expandSearch = () => {
        setIsSearchExpanded(true);

        if (searchRef.current) {
            triggerFocus(searchRef.current);
        }
    };
    const collapseSearch = () => setIsSearchExpanded(false);

    const debounceResult = debounce((event) => {
        const { value } = event.target;

        handleChange(value);
    });

    return (
        <form className={classNames(styles.filterContainer, {
            [className]: !!className,
        })} onSubmit={(e) => e.preventDefault()}>
            <div
                onClick={expandSearch}
                className={`input-wrapper${ isSearchExpanded ? ` ${ styles.expanded }` : '' }`}
            >
                <div className={`${ styles.svg_wrapper_search } svg-wrapper`}>
                    <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_281_750)">
                            <path
                                d="M21.7916 20.8031L16.1116 15.2138C17.599 13.5978 18.5129 11.4606 18.5129 9.10875C18.5122 4.07781 14.3684 0 9.25628 0C4.14421 0 0.000366211 4.07781 0.000366211 9.10875C0.000366211 14.1397 4.14421 18.2175 9.25628 18.2175C11.4651 18.2175 13.4909 17.4535 15.0822 16.1834L20.7842 21.7947C21.0621 22.0684 21.5131 22.0684 21.791 21.7947C22.0695 21.521 22.0695 21.0768 21.7916 20.8031ZM9.25628 16.8161C4.93091 16.8161 1.42452 13.3654 1.42452 9.10875C1.42452 4.85211 4.93091 1.40143 9.25628 1.40143C13.5817 1.40143 17.088 4.85211 17.088 9.10875C17.088 13.3654 13.5817 16.8161 9.25628 16.8161Z"
                                fill="#A7A7A7"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_281_750">
                                <rect width="22" height="22" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>

                <input
                    onChange={debounceResult}
                    className={styles.search}
                    id="search"
                    type="search"
                    placeholder={intl.formatMessage({ id: 'home-page.filter.search-input.placeholder' })}
                    name="search"
                    ref={searchRef}
                    onFocus={expandSearch}
                    onBlur={collapseSearch}
                />
            </div>
            <div className="d-flex">
                {tagsDataIsLoading && <Loader />}
                {!tagsDataIsLoading && tagsData && tagsData.length
                    ? tagsData.map((el) => (
                        <CheckBox
                            onClick={(clickState) => handleTagClick(clickState)}
                            choice={el}
                            key={el.name}
                        />
                    ))
                    : null}
            </div>
        </form>
    );
};

HomeFilter.defaultProps = {
    className: '',
};

export default HomeFilter;
