import { useNavigate } from 'react-router-dom';
import './fortuneWheelCard.scss';

// eslint-disable-next-line
// @ts-ignore
import taooCoin from 'assets/Images/taoo-coin.png';
// eslint-disable-next-line
// @ts-ignore
import cardBg from 'assets/Images/fortune-wheel-card-bg.png';
import useLocale from 'hooks/locale/useLocale';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';

interface FortuneWheelCardProps { className?: string }

const FortuneWheelCard = ({ className }: FortuneWheelCardProps) => {
    const lang = useLocale();
    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate(All_PATHS_NAMESPACES[lang].fortuneWheel.path);
    };

    return (
        <section onClick={handleNavigation} className={`fortune-wheel-card ${ className }`}>
            <img src={cardBg} alt="thumbnail" />
            <h4>Gagner tous les jours jusqu&apos;à</h4>
            <p>2000<img src={taooCoin} alt="taoo coin" /></p>
            <button type="button">
                Jouer & gagner
            </button>
        </section>
    );
};

FortuneWheelCard.defaultProps = {
    className: '',
};

export default FortuneWheelCard;
