/* eslint-disable react/button-has-type */
import classNames from 'classnames';
import { ButtonHTMLAttributes } from 'react';

interface PrimaryButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    isDanger?: boolean;
    isSuccess?: boolean;
}

const PrimaryButton = ({
    isDanger, isSuccess,
    type, children, className, ...rest
}: PrimaryButtonProps) => (
    <button
        type={type || 'button'}
        className={classNames('btn btn-lg-primary', {
            [className]: !!className,
            btnDanger:   isDanger,
            btnSuccess:  isSuccess,
        })}
        {...rest}
    >
        {children}
    </button>
);

PrimaryButton.defaultProps = {
    type:      'button',
    className: '',
    isDanger:  false,
    isSuccess: false,
};

export default PrimaryButton;
