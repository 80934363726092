import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Img from 'components/img/Img';

import styles from './index.module.scss';

function CheckBox({ choice, onClick }) {
    const [select, setSelect] = useState(false);

    return (
        <div
            onClick={() => {
                setSelect((select) => {
                    const inversedSelect = !select;

                    if (inversedSelect) {
                        onClick({
                            clicked: true,
                            id:      choice.id,
                        });
                    } else {
                        onClick({
                            clicked: false,
                            id:      choice.id,
                        });
                    }

                    return inversedSelect;
                });
            }}
            className={`d-flex align-items-center check-box ${ select ? 'selected' : '' } ${ styles['check-box'] }`}
        >
            {choice.icon && (
                <span className="img-wrapper mr-2">
                    <Img src={choice.icon} />
                </span>
            )}

            <div className="description">
                {choice.name}
                {/* <FormattedMessage id={choice.name.toLowerCase()} defaultMessage={choice.name} /> */}
            </div>
            <input name={choice.id} type="checkbox" hidden defaultChecked={select} />
        </div>
    );
}

export default CheckBox;
