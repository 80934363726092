import { endpoints } from 'constants/api';
import TaooServerClient from 'http/axios';
import { generateImageUrl } from 'services/url';

import * as types from './tag.types';

export const updateSelectedTags = (selectedTags) => async function (dispatch) {
    dispatch({ type: types.TAGS_UPDATE_SELECTED_REQUEST });

    try {
        const response = await TaooServerClient.post(endpoints.tags.update, {
            tags: selectedTags,
        });

        if (response.status == 200) {
            dispatch(updateSelectedTagsSuccess());
        } else {
            dispatch(updateSelectedTagsError(response));
        }
    } catch (error) {
        dispatch(updateSelectedTagsError(error));
    }
};

function updateSelectedTagsSuccess() {
    return {
        type:      types.TAGS_UPDATE_SELECTED_SUCCESS,
        isUpdated: true,
        error:     null,
    };
}

function updateSelectedTagsError(error) {
    return {
        type:      types.TAGS_UPDATE_SELECTED_ERROR,
        isUpdated: false,
        error,
    };
}

export const getAllSelectedTags = () => async function (dispatch) {
    dispatch({ type: types.TAGS_GET_ALL_SELECTED_REQUEST });

    try {
        const response = await TaooServerClient.get(endpoints.tags.getSelected);

        if (response.status == 200) {
            const data = formatTagsResponseData(response.data);

            dispatch(getAllSelectedTagsSuccess(data));
        } else {
            dispatch(getAllSelectedTagsError(response));
        }
    } catch (error) {
        dispatch(getAllSelectedTagsError(error));
    }
};

function getAllSelectedTagsSuccess(results) {
    return {
        type:     types.TAGS_GET_ALL_SELECTED_SUCCESS,
        selected: results,
        error:    null,
    };
}

function getAllSelectedTagsError(error) {
    return {
        type:     types.TAGS_GET_ALL_SELECTED_ERROR,
        selected: null,
        error,
    };
}

export const getAllTags = () => async function (dispatch) {
    dispatch({ type: types.TAGS_GET_ALL_REQUEST });

    try {
        const response = await TaooServerClient.get(endpoints.tags.getAll);

        if (response.status == 200) {
            const data = formatTagsResponseData(response.data);

            dispatch(getAllTagsSuccess(data));
        } else {
            dispatch(getAllTagsError(response));
        }
    } catch (error) {
        dispatch(getAllTagsError(error));
    }
};

function getAllTagsSuccess(results) {
    return {
        type:  types.TAGS_GET_ALL_SUCCESS,
        data:  results,
        error: null,
    };
}

function getAllTagsError(error) {
    return {
        type: types.TAGS_GET_ALL_ERROR,
        data: null,
        error,
    };
}

const formatTagsResponseData = (data) => {
    if (!data) return [];

    return data.map((tag) => ({
        ...tag,
        icon: generateImageUrl(tag.icon),
    }));
};
