import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useLocale from 'hooks/locale/useLocale';
import StoryCard from 'pages/story/story-card/StoryCard';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';

import FortuneWheelCard from '../fortune-wheel-card/FortuneWheelCard';
import HomeFilter from './filter/HomeFilter';
import styles from './index.module.scss';
import replacePathParams from 'services/utils/replacePathParams';
import useGetStories from './useGetStories';
import { generateImageUrl } from 'services/url';
import { useDispatch } from 'react-redux';
import { setShouldAdjustBasedOnStories } from 'store/app-layout/appLayout.action';

function HomeFiltersWrapper({
    handleChange, handleTagClick, wheelStatus, isWheelStatusLoading,
}) {
    const navigate = useNavigate();
    const lang = useLocale();

    const containerRef = useRef();
    const [shouldShow, setShouldShow] = useState(true);

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }

        let didScroll;
        const navbarHeight = containerRef.current.clientHeight;
        let lastScrollY = 0;
        const delta = 5;

        window.addEventListener('scroll', onScroll);

        function onScroll() {
            didScroll = true;
        }

        const interval = setInterval(function () {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 250);

        function hasScrolled() {
            const currentScrollY = window.scrollY;

            if (Math.abs(lastScrollY - currentScrollY) <= delta) {
                return;
            }

            // If current position > last position AND scrolled past navbar...
            if (currentScrollY > lastScrollY && currentScrollY > navbarHeight) {
                // Scroll Down
                setShouldShow(false);
            } else {
                // Scroll Up

                // body height
                const { body } = document;
                const html = document.documentElement;

                const bodyHeight = Math.max(
                    body.scrollHeight,
                    body.offsetHeight,
                    html.clientHeight,
                    html.scrollHeight,
                    html.offsetHeight,
                );

                if (currentScrollY + window.screen.height < bodyHeight) {
                    setShouldShow(true);
                }
            }

            lastScrollY = currentScrollY;
        }

        return () => {
            window.removeEventListener('scroll', onScroll);
            clearInterval(interval);
        };
    }, []);

    const { stories, isLoading: isStoriesLoading, hasActiveStories } = useGetStories();

    const goToStory = (story) => {
        const storageKey = 'cached-story';

        localStorage.setItem(storageKey, JSON.stringify(story));

        const route = replacePathParams(
            All_PATHS_NAMESPACES[lang].story.path,
            {},
            ':',
            {
                key: storageKey,
            },
        );

        navigate(route);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        const isTrue = hasActiveStories || (wheelStatus && wheelStatus.canPlay);

        dispatch(setShouldAdjustBasedOnStories(isTrue));

        return () => dispatch(setShouldAdjustBasedOnStories(false));
    }, [hasActiveStories, wheelStatus])

    return (
        <AnimatePresence exitBeforeEnter>
            {shouldShow ? (
                <motion.div
                    className={styles.filterWrapper}
                    key="form-animation"
                    ref={containerRef}
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.25 }}
                >
                    {hasActiveStories || (wheelStatus && wheelStatus.canPlay) ? (
                        <div className={styles.filterCardsWrapper}>
                            {wheelStatus && wheelStatus.canPlay && (
                                <FortuneWheelCard className={styles.filterWheelCard} />
                            )}
                            {stories.map((story, idx) => (
                                <StoryCard 
                                    key={`${story.id}-${idx}`} 
                                    image={generateImageUrl(story.thumbnail)} 
                                    onClick={() => goToStory(story)} 
                                />
                            ))}
                        </div>
                    ) : null}

                    <HomeFilter
                        className={styles.filterMain}
                        handleChange={handleChange}
                        handleTagClick={handleTagClick}
                    />
                </motion.div>
            ) : (
                <div key="margin-holder" style={{ marginTop: '104px' }} />
            )}
        </AnimatePresence>
    );
}

export default HomeFiltersWrapper;
