import * as types from './appLayout.types';

export const setAppLayoutMargin = (margin) => function (dispatch) {
    dispatch({
        type: types.APP_LAYOUT_SET_MARGIN,
        margin,
    });
};

export const setShouldAdjustBasedOnStories = (status) => function (dispatch) {
    dispatch({
        type: types.APP_LAYOUT_ADJUST_BASED_ON_STORIES,
        status,
    });
};
