import { useEffect, useState } from 'react';
import { CheckWheelStatusResponseType, checkWheelStatus } from './api';

export default function useGetPointsWheelStatus() {
    const [isWheelStatusLoading, setIsWheelStatusLoading] = useState(true);
    const [wheelStatus, setWheelStatus] = useState<CheckWheelStatusResponseType>(null);

    useEffect(() => {
        // checking fortune wheel status
        setIsWheelStatusLoading(true);
        checkWheelStatus()
                .then(setWheelStatus)
                .finally(() => setIsWheelStatusLoading(false));
    }, []);

    return { isWheelStatusLoading, wheelStatus };
}
