import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import IntroTip from 'components/widgets/intro-tip/IntroTip';

interface HomeIntroProps {
    onIntroComplete: () => void;
    onBeforeChange?: (nextStepIndex: number) => void;
    introState: {
        stepsEnabled: boolean;
        initialStep: number;
        steps: {
            element: string;
            intro: string;
        }[];
    };
}

const HomeIntro = ({ introState, onIntroComplete, onBeforeChange }: HomeIntroProps) => {
    const [introStepIndex, setIntroStepIndex] = useState(0);
    const intl = useIntl();

    const nextButtonText = useMemo(
        () =>
            // eslint-disable-next-line no-nested-ternary, implicit-arrow-linebreak
            introStepIndex === 0
                ? intl.formatMessage({ id: 'home-page-intro.explain-offer.step-1.button-text' })
                : introStepIndex === 1
                    ? intl.formatMessage({ id: 'home-page-intro.explain-offer.step-2.button-text' })
                    : undefined,
        [introStepIndex],
    );

    return (
        // eslint-disable-next-line
        // @ts-ignore
        <IntroTip
            hideHeader
            hidePrevButton
            hideNextButton={introStepIndex === 2}
            showButtons={introStepIndex < 2}

            // doneButtonText={intl.formatMessage({ id: 'home-page-intro.explain-offer.step-3.button-text' })}
            nextButtonText={nextButtonText}
            stepsEnabled={introState.stepsEnabled}
            steps={introState.steps}
            initialStep={introState.initialStep}
            onComplete={onIntroComplete}
            onBeforeChange={(nextStepIndex) => {
                setIntroStepIndex(nextStepIndex);
                onBeforeChange?.(nextStepIndex);
            }}
        />
    );
};

HomeIntro.defaultProps = {
    onBeforeChange: undefined,
};

export default HomeIntro;
