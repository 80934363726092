import './index.scss';

import React from 'react';

function NoResult({
    customStyle,
    headerStyle,
    messageStyle,
    message,
    header,
}) {
    return <article style={customStyle} className="no-result-wrapper">
        {header && <h1 style={headerStyle}>{header}</h1>}
        {message && <p style={messageStyle}>{message}</p>}
    </article>;
}

NoResult.defaultProps = {
    customStyle:  {},
    headerStyle:  {},
    messageStyle: {},
    header:       '',
};

export default NoResult;
