import { endpoints } from 'constants/api';
import TaooServerClient from 'http/axios';
import { sleep } from 'services/promise';

export enum FortuneWheelPrices {
    PRIZE_1 = 'PRIZE_1',
    PRIZE_2 = 'PRIZE_2',
    PRIZE_3 = 'PRIZE_3',
    PRIZE_4 = 'PRIZE_4',
    PRIZE_5 = 'PRIZE_5',
    PRIZE_6 = 'PRIZE_6',
    PRIZE_7 = 'PRIZE_7',
    PRIZE_8 = 'PRIZE_8',
}

export interface CheckWheelStatusResponseType {
    canPlay: boolean;
    days: {
        day: string;
        play: boolean;
        prize: FortuneWheelPrices;
    }[];
    remainDays: number;
}

export const checkWheelStatus = async () => {
    let response: CheckWheelStatusResponseType | null = null;


    try {
        const result  = await TaooServerClient.post<CheckWheelStatusResponseType>(endpoints.fortuneWheel.checkStatus);

        if (!result || !result?.data) {
            throw new Error('API_ERROR');
        }

        response = result.data;
    } catch (error: unknown) {
        console.error(error);
    }

    return response;
};

interface ResponseType {
    prize: FortuneWheelPrices;
}

export const onRequestWheelPlay = async (): Promise<ResponseType | null> => {
    let response: ResponseType | null = null;

    try {
        const result  = await TaooServerClient.post<ResponseType>(endpoints.fortuneWheel.play);

        if (!result || !result?.data) {
            throw new Error('API_ERROR');
        }

        response = result.data;

        // response = {
        //     prize: FortuneWheelPrices.PRIZE_1,
        // };

        // await sleep(2000);
    } catch (error: unknown) {
        console.error(error);
    }

    return response;
};



// wheel prime APIs
export enum WheelPrimePrizeTypes {
    offer = 'OFFER',
    point = 'POINT',
}

export interface WheelPrimePrizeItem {
    id: number | string;
    title: string;
    image: string;
    points?: number;
    brand?: string;
    brandLogo?: string;
    instruction?: string;
    type?: WheelPrimePrizeTypes;
}

export type GetWheelPrimePrizesResponseType = WheelPrimePrizeItem[];

export const getWheelPrimePrizes = async () => {
    let response: GetWheelPrimePrizesResponseType | null = null;

    try {
        const result  = await TaooServerClient.post<GetWheelPrimePrizesResponseType>(
            endpoints.fortuneWheelPrime.get,
        );

        if (!result || !result?.data) {
            throw new Error('API_ERROR');
        }

        response = result.data;
    } catch (error: unknown) {
        console.error(error);
    }

    return response;
};

export enum WheelPrimePrizeReasons {
    hasPlayedSevenDaysStraight = 'HAS_COMPLETED_7_DAYS',
    hasMinimumEntryPoints = 'HAS_500_POINTS',
}

export interface GetWheelPrimeStatusResponseTypeItem {
    canPlay: boolean;
    todayPlay: boolean;
    premium: boolean;
    reason: WheelPrimePrizeReasons;
}
export type GetWheelPrimeStatusResponseType = GetWheelPrimeStatusResponseTypeItem[];

export const getWheelPrimeStatus = async () => {
    let response: GetWheelPrimeStatusResponseType | null = null;

    try {
        const result  = await TaooServerClient.post<GetWheelPrimeStatusResponseType>(
            endpoints.fortuneWheelPrime.getStatus,
        );

        if (!result || !result?.data) {
            throw new Error('API_ERROR');
        }

        response = result.data;

        // response = [
        //     {
        //         canPlay:   true,
        //         todayPlay: false,
        //         reason:    WheelPrimePrizeReasons.hasMinimumEntryPoints,
        //     },
        //     {
        //         canPlay:   true,
        //         todayPlay: false,
        //         reason:    WheelPrimePrizeReasons.hasPlayedSevenDaysStraight,
        //     },
        // ];
    } catch (error: unknown) {
        console.error(error);
    }

    return response;
};

export interface PlayWheelPrimeResponseType {
    prize: WheelPrimePrizeItem;
    error: boolean;
    cardId?: number;
    points?: number;
}

export const playWheelPrime = async (reason: WheelPrimePrizeReasons) => {
    let response: PlayWheelPrimeResponseType | null = null;

    try {
        const result  = await TaooServerClient.post<PlayWheelPrimeResponseType>(
            endpoints.fortuneWheelPrime.play, { reason },
        );

        if (!result || !result?.data) {
            throw new Error('API_ERROR!');
        }

        response = result.data;

        // response = {
        //     prize: {
        //         id:        16,
        //         brand:     'TAOO',
        //         brandLogo: '5fbb7cf3-60df-4079-96c0-83b531b5cde5',
        //         image:     '6a709fb3-5081-4627-b7e9-e3aa34a679fa',
        //         title:     'APPLE iPhone 11 4Go 64Go x',
        //         type:      WheelPrimePrizeTypes.offer,

        //         // id:     16,
        //         // image:  'fb03f71f-e6fd-422b-9352-a3a99cc379ed',
        //         // title:  'prize 18',
        //         // points: 80,
        //         // type:   WheelPrimePrizeTypes.point,
        //     },
        //     error: false,
        // };

        // await sleep(2000);
    } catch (error: unknown) {
        console.error(error);
    }

    return response;
};
