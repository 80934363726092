import { motion } from 'framer-motion';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import PrimaryButton from 'components/form/button/PrimaryButton';
import PostOnboardingStep from 'components/post-onboarding/step';

const variants = {
    hidden: {
        y:       50,
        opacity: 0,
    },
    visible: {
        y:       0,
        opacity: 1,
    },
    exit: {
        y:       50,
        opacity: 0,
    },
};

const LevelUpPopup = ({ header, description, onClose }) => {
    const handleClick = useCallback(() => onClose?.(), [onClose]);

    return (
        <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="d-flex flex-column justify-content-between flex-grow-1"
            style={{ paddingBottom: '1.5rem' }}
        >
            <PostOnboardingStep header={header} description={description} />
            <PrimaryButton onClick={handleClick}>
                <FormattedMessage id="level.levelup.button-text" />
            </PrimaryButton>
        </motion.div>
    );
};

export default LevelUpPopup;
