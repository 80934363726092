import isEmpty from 'lodash/isEmpty';

import DraggablePopup from 'components/widgets/draggable-popup/DraggablePopup';
import PrimaryButton from 'components/form/button/PrimaryButton';
import Loader from 'components/loaders/loader';
import ShopItem from 'pages/home/shop-item';
import { useNavigate } from 'react-router-dom';
import useLocale from 'hooks/locale/useLocale';
import replacePathParams from 'services/utils/replacePathParams';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import { generateImageUrl } from 'services/url';
import { getCurrentUser } from 'services/utils/currentUser';
import useGetCurrentUser from 'hooks/user/useGetCurrentUser';

import './ClickoutOfferPopup.scss';

interface Props {
    shouldShow: boolean;
    onClick: () => void;
    isLoading: boolean;
    buttonText: string;
    title: string;
    paragraph: string;
    offer: any;
}

function ClickoutOfferPopup({
    title, paragraph,
    isLoading, buttonText, shouldShow, onClick, offer,
}: Props) {
    const navigate = useNavigate();
    const lang = useLocale();
    const user = useGetCurrentUser();

    const isLoggedIn = () => !isEmpty(user) || !isEmpty(getCurrentUser());

    const handleShopItemClick = (id) => {
        navigate(
            replacePathParams(All_PATHS_NAMESPACES[lang]['voucher-details'].path, {
                id,
            }),
        );
    };

    return (
        <DraggablePopup isDraggable={false} wrapperStyle={{ maxHeight: '95%' }} show={shouldShow} onClose={close}>
            <div className="clickout-offer">
                <h1>{title}</h1>

                <p>{paragraph}</p>
                {offer && (
                    <ShopItem
                        isFirstOffer
                        id="homepage-first-offer"
                        item={{
                            ...(offer ?? {}),
                            thumbnail: generateImageUrl(offer.thumbnail),
                        }}
                        hidePrice
                        onClick={() => isLoggedIn() ? handleShopItemClick(offer.id) : null}
                    />
                )}
                <PrimaryButton disabled={isLoading} onClick={onClick}>
                    {isLoading ? <Loader /> : buttonText}
                </PrimaryButton>
            </div>
        </DraggablePopup>
    );
}

export default ClickoutOfferPopup;
