import { motion } from 'framer-motion';

const variants = {
    layout:  true,
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit:    { opacity: 0, y: 20 },
};

const AnimatedItemsContainer = ({
    shouldAnimate = true, className, children, ...rest
}) => {
    const animationProps = shouldAnimate ? variants : {};

    return (
        <motion.div className={`flex-grow-1 ${ className }`} {...animationProps} {...rest}>
            {children}
        </motion.div>
    );
};

export default AnimatedItemsContainer;
