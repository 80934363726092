import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { useIntl } from 'react-intl';

import './IntroTip.scss';

/**
 *
 * @param {boolean} ?hideHeader
 * @param {boolean} ?hidePrevButton
 * @param {boolean} ?hideNextButton
 * @param {boolean} ?stepsEnabled
 * @param {boolean} initialStep
 * @param {Step[]} steps
 * @param {(stepIndex: number) => void} ?onExit
 * @param {() => void} ?onComplete
 * @param {() => void} ?onChange
 * @param {(nextStepIndex: number) => void} ?onBeforeChange
 * @param {() => void} ?onStart
 * @param {Options} options
 * @param {String} doneButtonText
 * @param {String} nextButtonText
 * @param {String} prevButtonText
 * @param {Boolean} showButtons
 * @returns
 */
const IntroTip = ({
    hideHeader = false,
    hidePrevButton = false,
    hideNextButton = false,
    options,
    stepsEnabled,
    steps,
    initialStep,
    onExit,
    onComplete,
    onChange,
    onBeforeChange,
    onStart,
    doneButtonText,
    nextButtonText,
    prevButtonText,
    showButtons = true,
}) => {
    const intl = useIntl();

    return (
        <Steps
            options={{
                ...options,
                showButtons,
                showBullets:     false,
                showProgress:    false,
                showStepNumbers: false,

                exitOnEsc:          false,
                exitOnOverlayClick: false,
                keyboardNavigation: false,
                scrollToElement:    true,

                tooltipClass: `intro-tip ${ hideHeader ? '--hideHeader' : '' } ${
                    hidePrevButton ? '--hidePrevButton' : ''
                } ${ hideNextButton ? '--hideNextButton' : '' }`,
                buttonClass:    'intro-button',
                highlightClass: 'intro-highlight',

                skipLabel: '',
                doneLabel: doneButtonText || intl.formatMessage({ id: 'intro-tooltip.done-button-text' }),
                nextLabel: nextButtonText || intl.formatMessage({ id: 'intro-tooltip.next-button-text' }),
                prevLabel: prevButtonText || intl.formatMessage({ id: 'intro-tooltip.prev-button-text' }),
            }}
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            onExit={(...params) => onExit?.(...params)}
            onStart={(...params) => onStart?.(...params)}
            onComplete={(...params) => onComplete?.(...params)}
            onChange={(...params) => onChange?.(...params)}
            onBeforeChange={(...params) => onBeforeChange?.(...params)}
        />
    );
};

export default IntroTip;
