import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useLocale from 'hooks/locale/useLocale';
import { setStorageItem } from 'services/storage';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';

export const CLICKOUT_OFFER_KEY = 'clickout-offer';

function ClickoutOffer() {
    const { clickoutCode } = useParams();
    const language = useLocale();
    const navigate = useNavigate();

    useEffect(() => {
        setStorageItem(CLICKOUT_OFFER_KEY, clickoutCode ?? 'unknown' );
        navigate(All_PATHS_NAMESPACES[language].home.path);
    }, []);

    return null;
}

export default ClickoutOffer;
