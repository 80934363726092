interface IInitObject {
    appId: string;
    subdomainName?: string;
    notifyButton?: Record<string, any>;
    allowLocalhostAsSecureOrigin?: boolean;
    [key: string]: any;
}

interface OneSignalParamsType {
    localhost: IInitObject;
    develop: IInitObject;
    production: IInitObject;
}

export const OneSignalParams: OneSignalParamsType = {
    localhost: {
        appId:                        'a20f58f3-c53e-445d-a26d-cc4c89e4b208',
        safari_web_id:                'web.onesignal.auto.650c091f-37ea-4fe0-8793-2961481353d2',
        allowLocalhostAsSecureOrigin: true,
        notifyButton:                 {
            enable: false,
        },
        subdomainName: 'taoo-test',
    },
    develop: {
        appId:         'f9810a15-fa27-4014-9d42-612111045176',
        safari_web_id: 'web.onesignal.auto.064b44a8-1dd7-4e10-9d87-452ef5b9c9dd',
        notifyButton:  {
            enable: false,
        },
    },
    production: {
        appId:         '',
        safari_web_id: '',
        notifyButton:  {
            enable: false,
        },
    },
};
