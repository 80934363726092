import styles from './index.module.scss';

function PostOnboardingStep({
    containerClass, header, headerStyle, children, footer, footerStyle,
}) {
    return (
        <div className={`${ containerClass || '' } ${ styles.stepContainer } flex-grow-1`}>
            <h1 style={headerStyle} className={styles.header}>
                {header}
            </h1>
            {children && children}
            <h1 style={footerStyle} className={styles.footer}>
                {footer}
            </h1>
        </div>
    );
}

export default PostOnboardingStep;
