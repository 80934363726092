import {
    isAfter, isValid, isWithinInterval, parse,
} from 'date-fns';
import moment from 'moment';

/**
 *
 * @param {Date} date
 * @param {[Date, Date]} range
 */
export function isDateInRange(date, range) {
    const [rangeStart, rangeEnd] = range;

    if (!isAfter(rangeEnd, rangeStart)) {
        console.error('rangeStart should be inferior to rangeEnd');

        return false;
    }

    const isInRange = isWithinInterval(date, {
        start: rangeStart,
        end:   rangeEnd,
    });

    return isInRange;
}

/**
 *
 * @param {*} date
 * @param {String} format
 */
export function isDateValid(date, format = 'P') {
    if (!date) {
        return false;
    }

    const parsedDate = parse(date, format, new Date());

    return isValid(parsedDate);
}

/**
 *
 * @param {Moment} eventDate
 * @param {String} eventDate
 * @returns
 */
export const getDateUnitsRemaining = (eventDate, unit = 'days') => {
    const todaysDate = moment();

    return eventDate.diff(todaysDate, unit);
};

/**
 *
 * @param {Date} start
 * @param {Date} end
 * @param {IntlShape} reactIntlInstance - [useIntl()] if passed, the fn will format the data according
 *                                        to the duration abbreviation defined in the translations.csv file
 * @returns
 */
export function durationAsDaysToSeconds(start, end, { reactIntlInstance } = { reactIntlInstance: null }) {
    const duration = moment.duration(moment(end).diff(moment(start)));

    // Get Days
    const days = Math.floor(duration.asDays()); // .asDays returns float but we are interested in full days only
    const daysFormatted = days ? `${ days }d ` : ''; // if no full days then do not display it at all

    // Get Hours
    const hours = duration.hours();
    const hoursFormatted = `${ hours }h `;

    // Get Minutes
    const minutes = duration.minutes();
    const minutesFormatted = `${ minutes }m `;

    const seconds = duration.seconds();
    const secondsFormatted = `${ seconds }s`;

    let formattedString = [daysFormatted, hoursFormatted, minutesFormatted, secondsFormatted].join('');

    if (reactIntlInstance != null && typeof reactIntlInstance === 'object') {
        const daysString = days > 0 ? `${ days }${ reactIntlInstance.formatMessage({ id: 'days.abbreviation' }) } ` : '';
        const hoursString = (() => {
            if (days > 0) {
                return '';
            }

            return hours > 0 ? `${ hours }${ reactIntlInstance.formatMessage({ id: 'hours.abbreviation' }) } ` : '';
        })();
        const minutesString = (() => {
            if (days > 0 || hours > 0) {
                return '';
            }

            if (minutes <= 0 && seconds > 0) {
                return `1${ reactIntlInstance.formatMessage({ id: 'minutes.abbreviation' }) }`;
            }

            return minutes > 0 ? `${ minutes }${ reactIntlInstance.formatMessage({ id: 'minutes.abbreviation' }) }` : '';
        })();

        formattedString = ` ${ daysString }${ hoursString }${ minutesString }`;
    }

    return {
        days,
        hours,
        minutes,
        seconds,
        formattedString,
    };
}
